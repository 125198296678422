import { useEffect,useLayoutEffect, useRef } from 'react';
import '../styles/index.css'
import Nav from '../components/Nav';
import Footer from './Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/footer.css'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
const Index = () => {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef()
  const elementSelector = gsap.utils.selector(ref)

  

  useLayoutEffect(() => {
    gsap.from(elementSelector(".serv1"),
      {
        autoAlpha: 0,
        x: -180,
        ease:"power4.out",
        scrollTrigger: {
          trigger: ref.current.querySelector(".services-container"),
          start: "top 80%",
          // markers: { startColor: "green", endColor: "red", fontSize: "12px" }
        },
      },
    )
    
  }, [])


  useLayoutEffect(() => {
    gsap.from(elementSelector(".serv2"),
      {
        autoAlpha: 0,
        x: 180,
        ease:"power4.out",

        scrollTrigger: {
          trigger: ref.current.querySelector(".services-container"),
          start: "top 80%",
          // markers: { startColor: "green", endColor: "red", fontSize: "12px" }
        },
      },
    )
    
  }, [])
    return (
        <div ref={ref} >
             <Nav/>
          <div id="about-link" className="about-container">

    <div className="about-image-container">
      <img className="about-image" src="images/code.PNG" alt=""></img>

    </div>
    <div className="about-text-container">
      <p className="about-text-name">Adeyemi Joshua Adepoju</p>
      <i>Software Engineer</i>
      <p className="about-text"> I am a software engineer who loves crafting technological
        solutions with both web and mobile application technologies.
      </p>
      {/* <div className="about-resume-button">
        <a href="cv/resumeee.pdf" target="blank">View Resume</a>
      </div> */}
    </div>
  </div>


  <hr className="about-hr"></hr>

  <a id="stacks-link"></a>

  <section className="services-container">


    <h3 className="services-h3">Technical Stacks</h3>
    <div className="services-div">
      <div className="services serv1 works-transition">
        <h4 className="services-heading">Mobile Application Development</h4>
        <img className="services-img" src="images/development.png" alt="mobile phone"></img>
        <p className="services-stacks">Stacks</p>
        <p className="services-p"> React Native</p>
      </div>

      <div className="services serv2 works-transition">
        <h4 className="services-heading">Web Development</h4>
        <img className="services-img" src="images/coding.png" alt="computer"></img>
        <p className="services-stacks">Stacks</p>
        <p className="services-p">Html,Css,Javascript,Typescript,React,Angular</p>

      </div>
    </div>

  </section>


  <hr className=" works-hr about-hr"></hr>

  <a id="projects-link"></a>
  <section className="works-container">

    <h3>Projects</h3>
    <div className="works-wrapper">



      <div className="works works-transition">
        <div className="works-image">
          <img src="images/crappo.png" alt=""></img>
        </div>

        <div className="works-content">
          <p className="works-name"> CryptoCurrency Page Ui
          </p>
          <p className="works-description">
A Responsive Ui landing page for a cryptocurrency investment platform. Built with React.js and using Css for styling and Gsap for simple animations.
          </p>
          <div className="works-project-container">
            <p></p>
            <div className="fonts">
                    <a href="https://ethcrappo.vercel.app/" target="blank">
                      <FontAwesomeIcon  className="fa" icon="link" />
                      </a>
            </div>
          </div>
        </div>
      </div>

      <div className="works works-reverse works-transition">
        <div className="works-image">
          <img src="images/kaboom.png" alt=""></img>
        </div>

        <div className="works-content">
          <p className="works-name"> Music Streaming App
          </p>
                <p className="works-description">A Music streaming web app that fetches and consumes data from the
                  spotify Api.
          </p>
          <div className="works-project-container">
            <p></p>
            <div className="fonts">
                    <a href="https://kaboom-lac.vercel.app/" target="blank">
                      <FontAwesomeIcon  className="fa" icon="link" />                    
                    </a>

                    <a href="https://github.com/The-indigo/kaboom" target="blank">
                      <FontAwesomeIcon  className="fa" icon={['fab', 'github']} />
                    </a>
            </div>
          </div>
        </div>
      </div>




      <div className="works works-transition">
        <div className="works-image">
          <img src="images/dashboard.png" alt=""></img>
        </div>

        <div className="works-content">
          <p className="works-name"> Inventory Management Dashbaord
          </p>
                <p className="works-description">
                  Worked on this dashbaord Ui using Next.js and Tailwind Css. You should take a look.
      
          </p>
          <div className="works-project-container">
            <p></p>
            <div className="fonts">
                   <a href="https://swaybidinventory.vercel.app/" target="blank">
                      <FontAwesomeIcon  className="fa" icon="link" />                    
                    </a>

                    <a href="https://github.com/The-indigo/inventory-ui" target="blank">
                      <FontAwesomeIcon  className="fa" icon={['fab', 'github']} />
                    </a>
                  </div>
                         
          </div>
        </div>
      </div>


    </div>



  </section>

        <Footer/>
        </div>
        
)    
}

export default Index

