import Home from './components/Home';
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { fab } from '@fortawesome/free-brands-svg-icons'
  import { faLink  } from '@fortawesome/free-solid-svg-icons'
import {
  Route,
Routes
} from "react-router-dom";
import Index from './components/Index';
const App = () => {
 library.add(fab, faLink)
 
  return (
    <>
    
      <Routes>
        <Route path='about' element={<Index />}></Route>
        <Route path='/' element={<Home />}></Route>
      </Routes>
    
    </>
 
  );
}

export default App;
