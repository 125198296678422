import React from "react"

const Footer = () => {
return (
        <>
            <hr></hr>
           
<footer>
    <p>Adeyemi Adepoju</p>
            <p>© { new Date().getFullYear()}</p>

</footer>
        </>
    )
}
export default Footer