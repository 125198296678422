import { useEffect, useRef } from 'react';
import '../styles/home.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gsap } from 'gsap';

const Home = () => {
    const iconsRef = useRef();
    const iconsSelector = gsap.utils.selector(iconsRef)
    useEffect(() => {
        gsap.from(iconsSelector(".fab"),
            {
                autoAlpha: 0,
                scale: 0,
                duration: 1,
                ease: "rough",
                stagger: {
                    each: 0.3,
                    amount: 1,
                    
                },
                
        }
        ) 
    },[])
    return (
        <>
        <div className="home-container">
        <div className="welcome-container">
            <div className="home-image">
                <img src="images/code.PNG" alt=""></img>
            </div>
            <div className="home-content">
                <div className="home-definition">
                    <h5><span>Hi, I'm </span><dfn>Adeyemi</dfn></h5>
                </div>

                <p> Highly Exceptional,entusiastic individual,
                    Eager to contribute to team's success.Passionate about
                    building products that serve millions of users
                </p>
            </div>

            </div>
            

        <div className="welcome-button-container">
            <Link className="welcome-button" to="/about">View Portfolio</Link>
        </div>

        <div className="home-fonts" ref={iconsRef}> 
            <a href="www.linkedin.com/in/adepoju-adeyemi-3a0846189" target="_blank" rel="noopener noreferrer"> 
                        <FontAwesomeIcon className="fab" icon={['fab', 'linkedin']}
                        /></a>
                   
                    <a href="https://twitter.com/alkekenji" target="_blank" rel="noopener noreferrer"> 
                      <FontAwesomeIcon  className="fab" icon={['fab', 'twitter']} />   
                    </a>
                    <a href="https://github.com/The-indigo" target="_blank" rel="noopener noreferrer"> 
                         <FontAwesomeIcon  className="fab" icon={['fab', 'github']} />
                    </a>
            </div>
        </div>
        {/* </div> */}
        </>
    )
}
export default Home;


