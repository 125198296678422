import '../styles/navbar.css'

const Nav = () => {
    return (
  <nav>
    <div className="navbar-container">
        <div className="logo-container">
            <img className="logo-img" src="images/wave.PNG" alt="Logo"></img>
        </div>
        <div className="navbar-link-container">
            <a className="navbar-link" href="/">Home</a>
            <a className="navbar-link" href="about">About</a>
            <a className="navbar-link" href="about#stacks-link">Stacks</a>
            <a className="navbar-link" href="about#projects-link">Projects</a>
        </div>
    </div>

</nav>    
)   
}
export default Nav;
